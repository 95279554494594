import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MastHead from "../components/masthead"
import ArticleList from "../components/masonry-list"
import { Col, Container, Row } from "reactstrap"

const CategoryPageTemplate = ({ data, location }) => {
  const articles = data.allMdx.nodes
  const {title, subtitle, hero} = data.preamble.childMdx.frontmatter  
  const body = data.preamble.childMdx.body
  const excerpt = data.preamble.childMdx.excerpt

  const List = ArticleList(articles)
  const shortcodes = {List}

  // console.log(hero.childImageSharp.fixed.src)

  return (
    <Layout title={title}>
        <SEO
        title={title}
        description={excerpt}
        image={hero.childImageSharp.fixed.src}
        />
        <MastHead overlay title={title} subtitle={subtitle} image={hero.childImageSharp.fixed.src}/>              
        <Container>        
        <Row>
          <Col lg="12" md="12" className="mx-auto"> 
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>               
        </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default CategoryPageTemplate

export const pageQuery = graphql`
  query CategoryByTag($category: String!) {
    preamble: file(name: {eq: $category}, extension: {in: ["md","mdx"]}) {
      id
      childMdx {
        frontmatter {
          title
          subtitle 
          hero {
            childImageSharp {
              fixed {
                src
              }
            }
          }         
        }
        body
        excerpt(pruneLength: 160)
      }
    }
    allMdx(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {category: {in: [$category]}}}) {
      nodes {
        frontmatter {
          title
          subtitle
          type
          duration
          date(formatString: "MMMM D, YYYY")          
          hero {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
        timeToRead
        excerpt(pruneLength: 160)
      }
    }
  }
`